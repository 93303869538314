import React, { useState } from 'react';
import axios from 'axios';
import {API_URL,REACT_API_URL} from '../../Global_Constants'
import { createContext, useEffect} from "react";
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Navbar from './Navbar';


const BetaForm = ({session,setSession}) => {
  const [redirect, setRedirect] = useState(false); 
  const [error, setError] = useState('');
  const [success,setSuccess]=useState(false)

  const handleChange = (event) => {
    if (!event.target.value) {
      setError('Input cannot be empty');
    } else {
      setError('');
    }
  };

  const setRefreshPage=()=>{
    setSuccess(false)
  }

  useEffect(()=>{
      setRefreshPage()
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      const response = await axios.post(
        API_URL + '/beta_release/',
        formData
      );
      if(response.data.data!==null)
      {
         setSuccess(true)
      }

    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

 

  return (
         <div className="container-fluid login-container">
          <div className="row ms-xl-5 me-xl-5">
            <Navbar/>
          </div>
          <div className="row mt-5 d-flex align-items-center justify-content-center">
          <form onSubmit={handleSubmit}>
              <div className="container-fluid " style={{height:'100%'}}>
          <div className="row d-flex align-items-center justify-content-center" style={{height:'100%'}}>
              <div className="col-12 col-lg-6" style={{height:'600px'}}>
                <div className="container-fluid">
                 {success===false && <div className="row d-flex flex-column justify-content-center align-items-center">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                         <h1 class="mb-4 source-sans-3 sign-up-beta-heading">OperforceAI</h1>
                    </div>
                    <div className="col-12  d-flex align-items-center justify-content-center p-0" style={{width:'700px',minWidth:'250px',gap:'10px'}}>
                        <input class="form-control beta-one-column"  type="text" name="business_name"  placeholder="Business Name" required />
                    </div> 
                    <div className="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center p-0 mt-4"style={{width:'700px',minWidth:'250px'}}>
                        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-end p-0  ">
                          <input class="form-control me-lg-2 beta-two-columns"  type="text" name="name"  placeholder="Full Name" required />
                        </div>
                        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-start p-0 mt-4 mt-lg-0">
                          <input class="form-control beta-two-columns"  type="text" name="job_title" onChange={handleChange} placeholder="Job Title" required />
                        </div>     
                   </div> 
                    <div className="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center p-0 mt-4"style={{width:'700px',minWidth:'250px'}}>
                        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-end p-0  ">
                          <input class="form-control me-lg-2 beta-two-columns"  type="email" name="email"  placeholder="Email" required />
                        </div>
                        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-start p-0 mt-4 mt-lg-0">
                          <input class="form-control beta-two-columns"  type="text" name="mobile" onChange={handleChange} placeholder="Mobile No" required />
                        </div>     
                   </div>               
                    <div className="col-12  d-flex align-items-center justify-content-center p-0 mt-4" style={{width:'700px',minWidth:'250px',gap:'10px'}}>
                         <input class="form-control beta-one-column" type="text" name="address"  placeholder="Address" required />
                    </div>
                    <div className="col-12  d-flex align-items-center justify-content-cente p-0 mt-4" style={{width:'700px',minWidth:'250px',gap:'10px'}}>
                        <textarea class="form-control beta-text-area"   type="text" name="message"  placeholder="Message"  />
                    </div>
                    <div className="col-12  d-flex align-items-center justify-content-center p-0 mt-2" >
                      <button type="submit" class="sign-up-beta-register-button register-button fonts-for-use" >Register</button>
                    </div>                   
                  </div>}
                  {
                   success===true && <div className="row  d-flex align-items-center justify-content-center " style={{height:'100%',marginTop:'180px'}}>
                        <div className="col-12 mt-5 d-flex flex-column align-items-center justify-content-center" style={{color:'green',height:'270px',width:'270px',backgroundColor:'rgb(176 209 225)',borderRadius:'50%',gap:'10px'}}>
                               <span class='mt-3 fonts-for-use'> Registered successfully   <FontAwesomeIcon icon={faCheck} style={{  color: 'green' }} /></span>
                               <a className="btn  btn-lg mt-2  go-to-home-btn" href='/' >Go to Home</a>
                        </div>
                  </div>
                  }
                </div>
              </div>
          </div>
        </div>
        </form>
          </div>
         </div>
  );
};

export default BetaForm;
