import React,{useEffect, useState} from 'react'
import Logo5 from '../images/Logo5.png'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../Global_Constants'

const Carers_form = ({jobTitle}) => {
    
   const [name,setName]=useState('')
   const [email,setEmail]=useState('')
   const [selectedResume,setSelectedResume]=useState(null)
   const [mobileNumber,setMobileNumber]=useState('')
   const [success,setSuccess]=useState(false)
   const [JobTitle,setJobTitle]=useState(jobTitle)

   const handleResumeUpload=(event)=>{
    const file = event.target.files[0];
    setSelectedResume(file)
   }
   


   const handleSubmit = async () => {
        try {
          const formData = new FormData();
          formData.append('name',name)
          formData.append('email',email)
          formData.append('mobile',mobileNumber)
          formData.append('cv_path',selectedResume)
          formData.append('position',jobTitle)
          const response = await axios.post(
        API_URL+'/cv_upload/',
            formData
          );
          if(response.data.data!==null)
          {
                setSuccess(true)
          }
        
        } catch (error) {
          console.error('Error logging in:', error);
        }
      };
       
       const setRefreshPageFunction=()=>{
        setSuccess(false)
       }

      useEffect(()=>{
         setRefreshPageFunction()
      },[])
  return (
    
          <div class='container-fluid  career-form-main'>
             <div className="row ms-xl-5 me-xl-5">
                <Navbar/>
               </div>
             {success===false && <div className="row  d-flex align-items-center justify-content-center">
                    <div className="col-12">
                    <div className="col-12 d-flex flex-column align-items-center justify-content-center mt-5">
                            <h4>{JobTitle}</h4>
                    </div>
                    <div className="col-12  d-flex flex-column align-items-center justify-content-center mt-3">
                             <div>
                             <label  style={{marginLeft:'10px'}}>Name</label>
                            <input class="form-control" style={{ height: "60px",width:'350px',minWidth:'250px',margin:'10px' }} type="text" onChange={(e)=>setName(e.target.value)} value={name} placeholder="Type Here..." required/>
                             </div>
                    </div>
                    <div className="col-12  d-flex flex-column align-items-center justify-content-center mt-3">
                            <div>
                               <label style={{marginLeft:'10px'}}>Email</label>
                               <input class="form-control" style={{  height: "60px",width:'350px' ,minWidth:'250px',margin:'10px' }} type="email" onChange={(e)=>setEmail(e.target.value)} value={email}  placeholder="hello@example.com" required />
                            </div>
                    </div>
                    <div className="col-12  d-flex flex-column align-items-center justify-content-center mt-3">
                            <div>
                               <label style={{marginLeft:'10px'}}>Mobile Number</label>
                               <input class="form-control" style={{  height: "60px",width:'350px' ,minWidth:'250px',margin:'10px' }} type="tel"  onChange={(e)=>setMobileNumber(e.target.value)} value={mobileNumber}  placeholder="Type Here..." required />
                            </div>
                    </div>
                    <div className="col-12  d-flex flex-column  justify-content-center me-lg-5 mt-3">
                           <input
                            id="resume-upload"
                            type="file"
                            accept=".doc, .docx, .pdf, image/*"
                            className="d-none"
                            onChange={handleResumeUpload}
                            />
                            <div class='d-flex flex-column justify-content-center align-items-center ' >
                                <label htmlFor="resume-upload" className="">
                                        {selectedResume && (
                                        <span className="career-resume-upload mb-3">Replace</span>
                                        )}
                                         {!selectedResume && (
                                        <span className="career-resume-upload mb-3 ">Upload Resume</span>
                                        )}
                                </label>
                                {selectedResume && (
                                <span className="mb-3">{selectedResume.name}</span>
                                )}
                            </div>
                    </div>
                    <div className="col-12  d-flex align-items-center justify-content-center">
                     <button type="submit" class="career-form-submit" onClick={handleSubmit}>Submit Application</button>
                    </div>
                    </div>
               </div>}
               {
                success===true && <div className="row d-flex  align-items-center justify-content-center mt-5" >
                        <div className="col-12 mt-5 d-flex flex-column align-items-center justify-content-center" style={{color:'green',height:'270px',width:'270px',backgroundColor:'rgb(176 209 225)',borderRadius:'50%',gap:'10px'}}>
                               <span class='mt-3'> Applied Successfully  <FontAwesomeIcon icon={faCheck} style={{  color: 'green' }} /></span>
                               <a className="btn btn-info btn-lg mt-2 go-to-home-btn " href='/careers' >Go to Careers</a>
                        </div>
                </div>
               }
    </div>
  )
}

export default Carers_form