import React from 'react'
import {useState} from 'react'
import Logo from '../../Assets/Logo.jpg'
import { useNavigate,Link } from 'react-router-dom'

const Org = () => {
  const [menu,setMenu]=useState("");
  const [show,setShow]=useState(false);
  const navigate=useNavigate()
  const sessionDataString = sessionStorage.getItem('sessionData');
  const sessionData = JSON.parse(sessionDataString);
  console.log(sessionData);

  
  return (
    <div class="org-main">
         <div className="org-top">
          <div className="org-image">
          <img src={Logo}></img>
           <h1>Operforce</h1>
          </div>
          <div className="org-corner">
            <h4>About Us</h4>
            <h4 ><Link to="/login" className="link">Login</Link></h4>
          </div>
         </div>
         <div className="org-middle">
              <div className="org-middle-left">
                  <h1>AI PLATFORM</h1> 
              </div>
              <div className="org-middle-right">
              <h4>At OperForce AI, Our mission is to digitalize and automate enterprise workflows that will enable them to operate and scale the business, and increase the revenue and profitability of the business</h4>
              <select onChange={e=>{setMenu(e.target.value);console.log(e.target.value);setShow(false);if(e.target.value==='business_segment'){setShow(true)}}} id="section_id" class="org-form-control">
                            <option value="">Please Select</option>
                            <option value="option1">Option 1</option>
                            <option value="business_segment">Business Segment</option>
                             <option value="optionN">Option N</option>
             </select> 
             <div  id="retail_section"  style={{display:  show ? 'block' : 'none'}}>
                        <div >
                            <a  style={{display:'block'}}><Link to="/apps" className="link">Manufacturing</Link></a>
                            <a   style={{display:'block'}}><Link to="/apps" className="link">IT</Link></a>
                            <a  style={{display:'block'}}><Link to="/apps" className="link">HealthCare</Link></a>
                            <a   style={{display:'block'}}><Link to="/apps" className="link">Hospitality</Link></a>                                                   
                        </div>
                </div>    
              </div>
         </div>
         <div className="org-bottom">
             <div className="org-left">
                
             </div>
             <div className="org-center">
                 <div className="org-center-bottom">

                 </div>
             </div>
             <div className="org-right">

             </div>
         </div>
    </div>
  )
}

export default Org

