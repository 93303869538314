import React,{useState} from 'react'
import Navbar from './Navbar'
import Logo from '../images/Logo5.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const About_us = () => {

  const [displayAddress,setDisplayAddress]=useState(false)
   
  return (
    <div className="container-fluid about-us-main position-relative">
    <div className="container-fluid position-relative" style={{height:'fit-content',minHeight:'100%'}}>
      <div className="row ms-xl-5 me-xl-5 fixed-top-main-pages-navbar">
        <Navbar menu={'About'} />
      </div>
      <div className="row d-flex flex-column align-items-center justify-content-center p-3">
        <div className="col-12 col-sm-8 col-xl-4 d-flex align-items-center justify-content-center page2-mission mt-5">
          <h5 className="about-us-h5 fonts-for-use">
          At Operforce AI, Our mission is to digitalize and automate enterprises workflows that enables them 
          to operate and scale the business, and increase revenue and profitability of the business.
          </h5>
        </div>
        <div className="col-12 d-flex justify-content-center align-items-center">
          <img src={Logo} alt="logo" style={{ height: '400px', width: '400px' }} />
        </div>
      </div>
      <div className="row fixed-bottom-right-button d-lg-none">
       {displayAddress===false && <button onClick={()=>setDisplayAddress(!displayAddress)} class='contact-us-button'>Contact us</button>}
      </div>
      {displayAddress===true && <div className="row fixed-bottom-right d-lg-none" onClick={()=>setDisplayAddress(false)}>
          <div className="col">
            <div class='d-flex justify-content-between'>
            <h5 class='contact-us-font-h5'>Address </h5>
            <FontAwesomeIcon icon={faXmark} size="1x" />
            </div>
            <hr class='about-us-conatct-line'></hr>
            <h6 class='contact-us-font contact-us-font-h6'>Gera's Imperium Alpha, Office 810, Rajaram Patil Nagar, Vitthal Nagar, Kharadi, Pune, Maharashtra, India</h6>
            <h5 class='contact-us-font-h5 mt-2'>Mobile No</h5>
            <hr class='about-us-conatct-line'></hr>
            <h6 class='contact-us-font-h6'>+91 74100 05971</h6>
            <h5 class='contact-us-font-h5 mt-2'>Email</h5>
            <hr class='about-us-conatct-line'></hr>
            <h6 class='contact-us-font-h6'>hello@operforce.com</h6>
          </div>
        </div>}
      <div className="row fixed-bottom-right d-none d-lg-block">
        <div className="col">
          <h5 class='contact-us-font-h5'>Address</h5>
          <hr class='about-us-conatct-line'></hr>
          <h6 class='contact-us-font contact-us-font-h6'>Gera's Imperium Alpha, Office 810, Kharadi, Pune, Maharashtra, India</h6>
          <h5 class='contact-us-font-h5 mt-2'>Mobile No</h5>
          <hr class='about-us-conatct-line'></hr>
          <h6 class='contact-us-font-h6'>+91 74100 05971</h6>
          <h5 class='contact-us-font-h5 mt-2'>Email</h5>
          <hr class='about-us-conatct-line'></hr>
          <h6 class='contact-us-font-h6'>hello@operforce.com</h6>
        </div>
      </div>
      <div className="row d-flex align-items-center justify-content-center mt-5 gap-3 position-absolute bottom-0" style={{width:'100%'}}>
              <h6 class='text-center footer-about-us-color mt-5 source-sans-3 copyright-font'>Operforce AI</h6>
              <h6 class='text-center mb-4 footer-about-us-color source-sans-3 copyright-font'>Copyright @ 2024 Operforce - All Rights Reserved.</h6>
      </div>
    </div>
  </div>
  
  )
}

export default About_us
// At Operforce AI, Our mission is to digitalize and automate enterprises workflows that enables them to operate and scale the business, and increase revenue and profitability of the business.