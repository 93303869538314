import React,{useEffect,useState} from 'react'
import './Main.css'
import { Link } from 'react-router-dom'
import logo from '../../Assets/BlackLogo.png'
import Logo5 from '../images/Logo5.png'
import Navbar from './Navbar'

const Page1 = () => {

  let rolling_text='We have released the first version - Software version 01 - of our Enterprise AI Platform™ and Enterprise AI Apps™ on July 12, 2024. So, if you are a MSME, SMB or large enterprise and want to digitalize and or AI-fy your business, register for the demo and we can talk more ...'


  return (
    <div className="container-fluid page1-main position-relative">
    <div className="container-fluid">
      <div className="row ms-xl-5 me-xl-5">
        <Navbar menu={'Home'} />
      </div>
      <div className="row p-3">
        <div className="col-sm-12 d-flex align-items-center justify-content-center page1-first-half">
          <img src={logo} alt="" className="img-fluid page1-img" style={{ maxHeight: '100px', minWidth: '200px' }} />
        </div>
        <div className="col-sm-12 d-flex align-items-start justify-content-center page1-second-half">
          <a href='/beta' className="btn page1-btn btn-lg mb-5 fonts-for-use">Sign up for Demo</a>
        </div>
        <div className="col-12  d-flex align-items-start justify-content-center fonts-for-use rolling-text-content mt-5">
        <div className="rolling-text-container mt-3">
          <div className="rolling-text fonts-for-use">{rolling_text}</div>
       </div>
        </div>
      </div>
    </div>
    <div className="row d-flex align-items-center justify-content-center mt-3 gap-3" style={{ width: '100%' }}>
      <h6 className="text-center footer-about-us-color mt-5 source-sans-3 copyright-font">Operforce AI</h6>
      <h6 className="text-center mb-4 footer-about-us-color source-sans-3 copyright-font">Copyright @ 2024 Operforce. All Rights Reserved.</h6>
    </div>
  </div>
  
  )
}

export default Page1