import React, { useState } from 'react';
import axios from 'axios';
import {API_URL,REACT_API_URL} from '../../Global_Constants.js' 
import { createContext, useEffect} from "react";

const LoginForm = ({session,setSession}) => {
  const [redirect, setRedirect] = useState(false); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      const response = await axios.post(
        API_URL+"/register_action/",
        formData
      );
      window.location.href=REACT_API_URL + "/login/"
      setRedirect(true);
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
        <form onSubmit={handleSubmit}>
              <div className="container-fluid login-container">
          <div className="row d-flex align-items-center justify-content-center" style={{height:'100%'}}>
              <div className="col-12 col-lg-6" style={{height:'600px'}}>
                <div className="container-fluid">
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                         <h1 class="mb-4">OperforceAI</h1>
                    </div>
                    <div className="col-12  d-flex align-items-center justify-content-center">
                    <input class="form-control"style={{ height: "60px",width:'400px',minWidth:'250px',margin:'10px' }} type="text" name="business_name"  placeholder="Business Name" required />
                    </div>                    
                    <div className="col-12  d-flex align-items-center justify-content-center">
                    <input class="form-control"style={{ height: "60px",width:'400px',minWidth:'250px',margin:'10px' }} type="text" name="email"  placeholder="Email" required />
                    </div>
                    <div className="col-12  d-flex align-items-center justify-content-center">
                    <input class="form-control"style={{ height: "60px",width:'400px',minWidth:'250px',margin:'10px' }} type="text" name="mobile"  placeholder="Mobile No" required />
                    </div>                          
                    <div className="col-12  d-flex align-items-center justify-content-center">
                    <input class="form-control"style={{  height: "60px",width:'400px' ,minWidth:'250px',margin:'10px' }} type="password" name="password"  placeholder="Password" required />
                    </div>
                    <div className="col-12  d-flex align-items-center justify-content-center">
                      <button type="submit" class="login-button" >Register</button>
                    </div>
                    <div className="col-12  d-flex align-items-center justify-content-center">
                    <a class="link-dark" href="/login/">Already have an account?</a>
                    </div>                    
                  </div>
                </div>
              </div>
          </div>
        </div>
        </form>
   
  );
};

export default LoginForm;
