import React from 'react'
import Logo5 from '../images/Logo5.png'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'

const Careers = ({setJobTitle}) => {
  return (
    <div class='container-fluid careers-main'>
      <div className="container-fluid">
      <div className="row operforce-careers ms-xl-5 me-xl-5">
           <Navbar menu={'Careers'}/>
         </div>
        <div className="row ps-4 pe-4 pe-md-5 ps-md-5 d-flex flex-column align-items-center justify-content-center">
             <div className="col-12 col-lg-7 mt-3 d-lg-flex">
                 <div className="col-12 col-lg-7">
                 <h1 class='career-heading' style={{lineHeight:'1.5',fontSize:'3rem'}}>Marketing Manager</h1>
                 </div>
                 <div className="col-12 col-lg-5 d-flex  justify-content-lg-center justify-content-start">
                   <h4 class='career-heading' style={{lineHeight:'3'}}>Kharadi, Pune, India</h4>
                 </div>
             </div>
             <div className="col-12 col-lg-7">
             <hr style={{height:'2px'}}></hr>
             </div>
             <div className="col-12 col-lg-7  mt-4">
               <h6 class='careers-h6'>As a Marketing Manager, you will play a pivotal role in developing and executing strategic marketing initiatives to promote our Enterprise
                AI Platform and Enterprise AI Apps. You will be responsible for crafting compelling marketing campaigns, managing digital and traditional marketing channels, and building
                strong relationships with enterprises and partners. </h6>
             </div>
             <div className="col-12 col-lg-7  mt-3">
                <h5>Responsibilities</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>Develop and implement comprehensive marketing strategies to drive awareness and adoption of our enterprise AI platform and Enterprise AI Apps.</li>
                    <li class='careers-li'>Collaborate with cross-functional teams to create compelling marketing collateral, including website content, blog posts, case studies, and whitepapers.</li>
                    <li class='careers-li'>Plan and execute targeted marketing campaigns across digital and traditional channels, including email, social media, SEM, SEO, and events.</li>
                    <li class='careers-li'>Manage the company's online presence, including website optimization, content creation, and social media management.</li>
                    <li class='careers-li'>Analyze marketing metrics and KPIs to measure the effectiveness of campaigns and optimize marketing efforts for maximum impact.</li>
                    <li class='careers-li'>Conduct market research and competitive analysis.</li>
                    <li class='careers-li'>Build and maintain relationships with clients, partners, industry influencers, and media outlets to enhance brand visibility and credibility.</li>
                    <li class='careers-li'>Coordinate with sales teams to develop marketing materials and tools to support lead generation and conversion efforts.</li>
                    <li class='careers-li'>Stay updated on industry trends and best practices in marketing and technology, and incorporate relevant insights into our marketing strategies.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Requirements</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>1-2 years of experience in marketing, with a focus on technology and Enterprise AI</li>
                    <li class='careers-li'>Bachelor's degree in Marketing, Business Administration, or a related field.</li>
                    <li class='careers-li'>Proven track record of developing and executing successful marketing campaigns across digital and traditional channels.</li>
                    <li class='careers-li'>Strong understanding of digital marketing tactics and tools, including email marketing, social media management, SEM, SEO, and analytics platforms.</li>
                    <li class='careers-li'>Excellent communication and interpersonal skills, with the ability to build rapport and credibility with internal and external stakeholders.</li>
                    <li class='careers-li'>Strong analytical skills, with the ability to interpret data and metrics to drive informed marketing decisions.</li>
                    <li class='careers-li'>Creative thinker with a passion for innovation and problem-solving.</li>
                    <li class='careers-li'>Ability to multitask and prioritize in a fast-paced, deadline-driven environment.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 mb-5 col-lg-7 ">
             <Link to='/careers_form'><button  className="btn btn-primary" onClick={()=>setJobTitle('Marketing Manager')}>Apply Now</button></Link> 
             </div>
         </div>
         <div className="row ps-4 pe-4 pe-md-5 ps-md-5 d-flex flex-column align-items-center justify-content-center">
           <div className="col-12 col-lg-7 mt-3 d-lg-flex">
                 <div className="col-12 col-lg-7">
                 <h1 class='career-heading' style={{lineHeight:'1.5',fontSize:'3rem'}}>Test Engineer</h1>
                 </div>
                 <div className="col-12 col-lg-5 d-flex  justify-content-lg-center justify-content-start">
                   <h4 class='career-heading' style={{lineHeight:'3'}}>Kharadi, Pune, India</h4>
                 </div>
             </div>
             <div className="col-12 col-lg-7">
             <hr style={{height:'2px'}}></hr>
             </div>
             <div className="col-12 mt-4 col-lg-7 ">
               <h6 class='careers-h6'>As a Test Engineer, you will play a crucial role in ensuring the quality and reliability of our Enterprise AI platform and Enterprise AI Apps.
                You will be responsible for designing, implementing, and executing comprehensive testing strategies to validate the functionality, performance,
                 and security of our software solutions. This role offers an excellent opportunity for growth and development in a fast-paced and innovative environment.</h6>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Responsibilities</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>Develop and execute test plans, test cases, and test scripts to validate the functionality, usability, and performance of our enterprise AI platform and Apps.</li>
                    <li class='careers-li'>Conduct thorough regression testing to ensure that new features and enhancements do not adversely impact existing functionality.</li>
                    <li class='careers-li'>Perform manual and automated testing of web-based applications to identify defects and areas for improvement.</li>
                    <li class='careers-li'>Collaborate closely with development and product teams to identify and prioritize testing requirements and ensure timely resolution of issues.</li>
                    <li class='careers-li'>Create and maintain automated test suites using Cloud CI/CD tools and frameworks to streamline testing processes and improve efficiency.</li>
                    <li class='careers-li'>Monitor and analyze test results, metrics, and feedback to identify trends, patterns, and areas for optimization.</li>
                    <li class='careers-li'>Stay updated on industry trends, enterprise AI, and best practices in software testing and quality assurance, and incorporate relevant insights into our processes and strategies.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Requirements</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>0-2 years of experience in software testing or quality assurance, preferably in a technology-driven environment.</li>
                    <li class='careers-li'>Bachelor's degree in Computer Science, Software Engineering, or a related field.</li>
                    <li class='careers-li'>Strong understanding of Cloud, CI/CD tools, and techniques, with a passion for delivering high-quality software solutions.</li>
                    <li class='careers-li'>Experience with manual and automated testing of web-based applications, with proficiency in testing tools and frameworks.</li>
                    <li class='careers-li'>Excellent communication and teamwork abilities, with the ability to collaborate with all stakeholders.</li>
                    <li class='careers-li'>Ability to adapt to fast-paced environments and tight deadlines while maintaining attention to detail and quality.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 mb-5 col-lg-7 ">
                 <Link to='/careers_form'><button  className="btn btn-primary" onClick={()=>setJobTitle('Test Engineer')}>Apply Now</button></Link> 
             </div>
         </div>
         <div className="row d-flex align-items-center justify-content-center mt-5 gap-3">
              <h6 class='text-center footer-about-us-color source-sans-3 copyright-font'>Operforce AI</h6>
              <h6 class='text-center mb-4 footer-about-us-color source-sans-3 copyright-font'>Copyright @ 2024 Operforce - All Rights Reserved.</h6>
         </div>
      </div>
    </div>
  )
}

export default Careers
