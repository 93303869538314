import React from 'react'
import { Link } from 'react-router-dom'
import { FaSearch } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import './Main_Pages.css'

const Apps = () => {
   return (
      <div class="apps-main">
         <div className="video-background">
            <video autoPlay muted loop id="background-video">
               <source src="https://storage.googleapis.com/operforce/Assets/Videos/8419548-hd_1080_1080_30fps.mp4" type="video/mp4" />
               Your browser does not support the video tag.
            </video>
         </div>
         <div className="apps-top">

         </div>
         <div className="apps-middle" style={{ display: 'flex', justifyContent: 'center' }}>
            <div class="apps-search-container">
               <input type="text" placeholder="Search..." class="apps-search-input" />
               <span class="apps-search-icon-wrapper">
                  <FaSearch class="apps-search-icon" />
               </span>
            </div>
         </div>
         <div className="apps-bottom container-lg">
            {/* <div className="apps-bottom1">
               
                <Link to="/accounting" className='link'>
                  <div className="apps-col2-container">
                     <div className="apps-column" id="accounting">
                        <FontAwesomeIcon icon={faCalculator} style={{ fontSize: '40px', color: 'white' }} />
                     </div>
                     <div className="apps-text">Accounting</div>
                  </div>
               </Link>
               <Link to="/sales" className='link'>
                  <div className="apps-col2-container">
                     <div className="apps-column" id="sales">
                        <FontAwesomeIcon icon={faSignal} style={{ fontSize: '40px', color: 'white' }} />
                     </div>
                     <div className="apps-text">Sales</div>
                  </div>
               </Link>
               <Link to="/pos" className='link'>
                  <div className="apps-col3-container">
                     <div className="apps-column" id="pointOfSales">
                        <FontAwesomeIcon icon={faMoneyCheckDollar} style={{ fontSize: '48px', color: 'white' }} />
                     </div>
                     <div className="apps-text">Point of Sales</div>
                  </div>
               </Link>
               <div className="apps-col4-container">
                  <div className="apps-column" id="invoicing">
                     <FontAwesomeIcon icon={faReceipt} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">Invoicing</div>
               </div>
               <div className="apps-col5-container">
                  <div className="apps-column" id="marketing">
                     <FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">Marketing</div>
               </div>
               <Link to={ '/email' } className='link'>
                  <div className="apps-col6-container">
                     <div className="apps-column" id="email">
                        <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '48px', color: 'white' }} />
                     </div>
                     <div className="apps-text">Email</div>
                  </div>
               </Link>
               <Link to="/marketing" className='link'>
                  <div className="apps-column dummy7">
                  <FontAwesomeIcon icon={faComment} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">Marketing</div>
               </Link>
            </div>
            <div className="apps-bottom2">
               <div className="apps-col1-container">
                  <div className="apps-column" id="support">
                     <FontAwesomeIcon icon={faPhone} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">Support</div>
               </div>
               <Link to="/pay" className='link'> <div className="apps-col2-container">
                  <div className="apps-column" id="enterprisepay">
                     <FontAwesomeIcon icon={faCommentsDollar} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">Enterprisepay</div>
               </div></Link>
               <Link to="/hr" className='link'>
                  <div className="apps-col3-container">
                     <div className="apps-column" id="pointOfSales">
                        <FontAwesomeIcon icon={faUserGroup} style={{ fontSize: '48px', color: 'white' }} />
                     </div>
                     <div className="apps-text">HR</div>
                  </div>
               </Link>
               <Link to="/inventory" className='link'>
               <div className="apps-col4-container">
                  <div className="apps-column dummy4" id="inventory">
                     <FontAwesomeIcon icon={faStore} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">Inventory</div>
               </div>
               </Link>
               <Link to="/payroll" className='link'>
               <div className="apps-col4-container">
                  <div className="apps-column dummy4" id="marketing">
                  <FontAwesomeIcon icon={faReceipt} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">Payroll</div>
               </div>
               </Link>
               <Link to="/crm" className='link'>
               <div className="apps-col4-container">
                  <div className="apps-column dummy6" >
                  <FontAwesomeIcon icon={faReceipt} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">CRM</div>
               </div>
               </Link>
               <Link to="/threads" className='link'>
               <div className="apps-column dummy7">
               <FontAwesomeIcon icon={faComment} style={{ fontSize: '48px', color: 'white' }} />
               </div>
               <div className="apps-text">Threads</div>
               </Link>
            </div>
            <div className="apps-bottom3">
               <Link to="/gst" className='link'>
                  <div className="apps-column dummy1">
                  <FontAwesomeIcon icon={faIndianRupeeSign} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">GST</div>
                  </Link>
               <div className="apps-column dummy2">

               </div>
               <div className="apps-column dummy3">

               </div>
               <div className="apps-column dummy4">

               </div>
               <div className="apps-column dummy5">

               </div>
               <div className="apps-column dummy6">

               </div>
               <div className="apps-column dummy7">

               </div>
            </div>
            <div className="apps-bottom4">
               <div className="apps-column dummy1">

               </div>
               <div className="apps-column dummy2">

               </div>
               <div className="apps-column dummy3">

               </div>
               <div className="apps-column dummy4">

               </div>
               <div className="apps-column dummy5">

               </div>
               <div className="apps-column dummy6">

               </div>
               <div className="apps-column dummy7">

               </div>
            </div>
            <div className="apps-bottom5">
               <div className="apps-column dummy1">

               </div>
               <div className="apps-column dummy2">

               </div>
               <div className="apps-column dummy3">

               </div>
               <div className="apps-column dummy4">

               </div>
               <div className="apps-column dummy5">

               </div>
               <div className="apps-column dummy6">

               </div>
               <Link to="/user_managment" className='link'>
               <div className="apps-column dummy7">
               <FontAwesomeIcon icon={faUsers} style={{ fontSize: '48px', color: 'white' }} />
               </div>
               <div className="apps-text">User Managment</div>
               </Link>
            </div> */}
            <div className='row mt-5'>
               <div className='col-2'>
                  <Link to="/accounting" className='link'>
                     <div className="apps-col2-container">
                        <div className="apps-column" id="accounting">
                           <FontAwesomeIcon icon={faCalculator} style={{ fontSize: '40px', color: 'white' }} />
                        </div>
                        <div className="apps-text">Accounting</div>
                     </div>
                  </Link>

               </div>
               <div className='col-2'>
                  <Link to="/sales" className='link'>
                     <div className="apps-col2-container">
                        <div className="apps-column" id="sales">
                           <FontAwesomeIcon icon={faSignal} style={{ fontSize: '40px', color: 'white' }} />
                        </div>
                        <div className="apps-text">Sales</div>
                     </div>
                  </Link>

               </div>

               <div className='col-2'>
                  <Link to="/pos" className='link'>
                     <div className="apps-col3-container">
                        <div className="apps-column" id="pointOfSales">
                           <FontAwesomeIcon icon={faMoneyCheckDollar} style={{ fontSize: '48px', color: 'white' }} />
                        </div>
                        <div className="apps-text">Point of Sales</div>
                     </div>
                  </Link>

               </div>
               <div className='col-2 '>
                  <div className="apps-col4-container">
                     <div className="apps-column" id="invoicing">
                        <FontAwesomeIcon icon={faReceipt} style={{ fontSize: '48px', color: 'white' }} />
                     </div>
                     <div className="apps-text">Invoicing</div>
                  </div>

               </div>
               {/* <Link to="/marketing" className='link'>
                  <div className="apps-column dummy7">
                  <FontAwesomeIcon icon={faComment} style={{ fontSize: '48px', color: 'white' }} />
                  </div>
                  <div className="apps-text">Marketing</div>
               </Link> */}
               <div className='col-2'>
                  <Link to="/marketing" className='link'>
                     <div className="apps-col5-container">
                        <div className="apps-column" id="marketing">
                           <FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: '48px', color: 'white' }} />
                        </div>
                        <div className="apps-text">Marketing</div>
                     </div>
                  </Link>


               </div>
               <div className='col-2'>
                  <Link to={'/email'} className='link'>
                     <div className="apps-col6-container">
                        <div className="apps-column" id="email">
                           <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '48px', color: 'white' }} />
                        </div>
                        <div className="apps-text">Email</div>
                     </div>
                  </Link>

               </div>

            </div>
            <div className='row mt-3'>
               <div className='col-2'>

                  <div className="apps-col1-container">
                     <div className="apps-column" id="support">
                        <FontAwesomeIcon icon={faPhone} style={{ fontSize: '48px', color: 'white' }} />
                     </div>
                     <div className="apps-text">Support</div>
                  </div>

               </div>
               <div className='col-2'>
                  <Link to="/pay" className='link'> <div className="apps-col2-container">
                     <div className="apps-column" id="enterprisepay">
                        <FontAwesomeIcon icon={faCommentsDollar} style={{ fontSize: '48px', color: 'white' }} />
                     </div>
                     <div className="apps-text">Enterprisepay</div>
                  </div></Link>

               </div>
               <div className='col-2'>
                  <Link to="/hr" className='link'>
                     <div className="apps-col3-container">
                        <div className="apps-column" id="pointOfSales">
                           <FontAwesomeIcon icon={faUserGroup} style={{ fontSize: '48px', color: 'white' }} />
                        </div>
                        <div className="apps-text">HR</div>
                     </div>
                  </Link>

               </div>
               <div className='col-2'>
                  <Link to="/inventory" className='link'>
                     <div className="apps-col4-container">
                        <div className="apps-column dummy4" id="inventory">
                           <FontAwesomeIcon icon={faStore} style={{ fontSize: '48px', color: 'white' }} />
                        </div>
                        <div className="apps-text">Inventory</div>
                     </div>
                  </Link>

               </div>
               <div className='col-2'>
                  <Link to="/payroll" className='link'>
                     <div className="apps-col4-container">
                        <div className="apps-column dummy4" id="marketing">
                           <FontAwesomeIcon icon={faReceipt} style={{ fontSize: '48px', color: 'white' }} />
                        </div>
                        <div className="apps-text">Payroll</div>
                     </div>
                  </Link>

               </div>
               <div className='col-2'>
                  <Link to="/crm" className='link'>
                     <div className="apps-col4-container">
                        <div className="apps-column dummy6" >
                           <FontAwesomeIcon icon={faReceipt} style={{ fontSize: '48px', color: 'white' }} />
                        </div>
                        <div className="apps-text">CRM</div>
                     </div>
                  </Link>

               </div>
            </div>

            <div className='row mt-3'>
               <div className='col-2'>
                  <Link to="/threads" className='link'>
                     <div className="apps-col2-container">
                        <div className="apps-column dummy1">
                           <FontAwesomeIcon icon={faComment} style={{ fontSize: '48px', color: 'white' }} />
                        </div>
                        <div className="apps-text">Threads</div>
                     </div>

                  </Link>
               </div>
               <div className='col-2'>
                  <Link to="/user_managment" className='link'>
                     <div className="apps-col2-container">
                        <div className="apps-column dummy2">
                           <FontAwesomeIcon icon={faUsers} style={{ fontSize: '48px', color: 'white' }} />
                        </div>
                        <div className="apps-text">User Managment</div>
                     </div>
                  </Link>
               </div>


               <div className='col-2'>
               <Link to="/gst" className='link'>
                  <div className="apps-col3-container">
                     <div className="apps-column dummy3" >
                     <FontAwesomeIcon icon={faIndianRupeeSign} style={{ fontSize: '48px', color: 'white' }} />
                     </div>
                     <div className="apps-text"> GST</div>
                  </div>
                  </Link>

               </div>

               <div className='col-2'>
                  <div className="apps-col4-container">
                     <div className="apps-column dummy4" >

                     </div>
                     <div className="apps-text"></div>
                  </div>


               </div>
               <div className='col-2'>
                  <div className="apps-col4-container">
                     <div className="apps-column dummy5" >

                     </div>
                     <div className="apps-text"></div>
                  </div>

               </div>
               <div className='col-2'>

                  <div className="apps-col6-container">
                     <div className="apps-column dummy6" >

                     </div>
                     <div className="apps-text"></div>
                  </div>


               </div>
            </div>
            <div className='row mt-3'>
               <div className='col-2'>

                  <div className="apps-col2-container">
                     <div className="apps-column dummy1">

                     </div>
                     <div className="apps-text"></div>
                  </div>





               </div>
               <div className='col-2'>

                  <div className="apps-col2-container">
                     <div className="apps-column dummy2">

                     </div>
                     <div className="apps-text"></div>
                  </div>



               </div>
               <div className='col-2'>

                  <div className="apps-col3-container">
                     <div className="apps-column dummy3" >

                     </div>
                     <div className="apps-text"></div>
                  </div>


               </div>
               <div className='col-2'>
                  <div className="apps-col4-container">
                     <div className="apps-column dummy4" >

                     </div>
                     <div className="apps-text"></div>
                  </div>


               </div>
               <div className='col-2'>
                  <div className="apps-col4-container">
                     <div className="apps-column dummy5" >

                     </div>
                     <div className="apps-text"></div>
                  </div>

               </div>
               <div className='col-2'>

                  <div className="apps-col6-container">
                     <div className="apps-column dummy6" >

                     </div>
                     <div className="apps-text"></div>
                  </div>


               </div>
            </div>
         </div>
      </div>
   )
}

export default Apps