import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { API_URL, REACT_API_URL } from '../../Global_Constants.js';

const LoginForm = ({ session, setSession }) => {
  const [redirect, setRedirect] = useState(false); 
  const [subdomain, setSubdomain] = useState('');
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);  
  const [isLocationAllowed, setIsLocationAllowed] = useState(false); // New state for button disable

  useEffect(() => {
    // Extract subdomain from the current URL
    const currentURL = window.location.hostname;
    const parts = currentURL.split('.');
    if (parts.length > 2) {
      const subdomain = parts[0];
      setSubdomain(subdomain);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      formData.append('business_name', subdomain);
      formData.append('latitude', location.latitude);
      formData.append('longitude', location.longitude);
      const response = await axios.post(
        API_URL + "/login_action/",
        formData
      );
      const sessionData = response.data;
      const sessionDataString = JSON.stringify(sessionData);
      localStorage.setItem('sessionData', sessionDataString);
      sessionStorage.setItem('sessionData', sessionDataString);
      // console.log(response)
      // setSession(sessionDataString)
      console.log(response.data.url);
      if(response.data.url!=''){
        window.location.href = response.data.url;
      }
      else{
        alert('Authontication failed please try again or contact admin!')
      }
       // commented by shubham till further instruction
      // window.location.href=REACT_API_URL + "/org_home/"
      // Set redirect state to true after successful login
      setRedirect(true);
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setIsLocationAllowed(true); // Enable button if location is allowed
        },
        (err) => {
          setError('Geolocation is not supported by this browser.');
          setIsLocationAllowed(false); // Disable button if location is not allowed
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
      setIsLocationAllowed(false); // Disable button if geolocation is not supported
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid login-container">
        <div className="row d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
          <div className="col-12 col-lg-6" style={{ height: '300px' }}>
            <div className="container-fluid">
              <div className="row d-flex flex-column justify-content-center align-items-center">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <h1 className="mb-4">OperforceAI</h1>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <input className="form-control" style={{ height: "60px", width: '400px', minWidth: '250px', margin: '10px' }} type="text" name="email" placeholder="Email" required />
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <input className="form-control" style={{ height: "60px", width: '400px', minWidth: '250px', margin: '10px' }} type="password" name="password" placeholder="Password" required />
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <button type="submit" className="login-button" disabled={!isLocationAllowed}>Log in</button>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <a className="link-dark" href="/register/">Create an Account</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
