import React, { useState } from 'react';
import axios from 'axios';
import {API_URL,REACT_API_URL} from '../../Global_Constants.js' 
import { createContext, useEffect} from "react";
import success_right_image from '../../Assets/successful_right_image.png'
import { useNavigate } from 'react-router-dom';

const Update_Password = ({session,setSession}) => {
  const [redirect, setRedirect] = useState(false);
  const sessionDataString = sessionStorage.getItem('sessionData');
  const sessionData = JSON.parse(sessionDataString);
  const [errorMessage,setErrorMessage]=useState('')
  const [error,setError]=useState(false)
  const [userName,setUserName]=useState('')
  const [password,SetPassword]=useState('')
  const [retypePassword,setRetypePassword]=useState('')
  const [successUpdatePassword,setSuccessUpdatePassword]=useState(false)
  const navigate = useNavigate();


  const handleSubmit = async () => {
    if(password=='')
    {
        setError(true)
        setErrorMessage('Password cannot be null')
        return false;
    }
    try {
      const formData = new FormData();
      console.log('hello')
      formData.append('org_id',sessionData.session_record.id)
      formData.append('user_id',sessionData.session_record.login_id)
      formData.append('password1',password)
      formData.append('password2',retypePassword)
      const response = await axios.post(
        API_URL+"/user_update_password/",
        formData
      );
      if(response.data.data.status==2)
      {
            setError(true)
            setErrorMessage(response.data.data.message)
      }
      if(response.data.data.status==1)
      {
        setError(true)
        setErrorMessage(response.data.data.message)
      }
      if(response.data.data.status==3)
      {
        setError(false)
        setSuccessUpdatePassword(true)
      }
      console.log(response.data.data)
      setRedirect(true);
    } catch (error) {
        setError(false)
      console.error('Error logging in:', error);
    }
  };

  const goBackToPreviousURL=()=>{
    navigate(-1); 
  }
  

  return (
      successUpdatePassword===true ? <div class='container-fluid' style={{height:'100vh'}}>
           <div className="row d-flex justify-content-center align-items-center p-3" style={{height:'100%'}}>
            <div className="col-lg-4 col-xl-3 col-md-6  col-12 update-password-success d-flex flex-column align-items-center justify-content-center gap-3 p-md-5 p-3">
                   <img src={success_right_image} alt='success_right_image' style={{width:'80px',height:'80px'}} />
                   <h4 style={{textAlign:'center'}}>Password Changed!</h4>
                   <h6 style={{textAlign:'center'}}>Password has been changed Successfully</h6>
                   <a className="btn btn-success btn-lg mt-3" onClick={goBackToPreviousURL}>Continue</a>
            </div>
           </div>
      </div>:<div className="container-fluid login-container">
      <div className="row d-flex align-items-center justify-content-center" style={{height:'100%'}}>
          <div className="col-12 col-lg-6" style={{height:'600px'}}>
            <div className="container-fluid">
              <div className="row d-flex flex-column justify-content-center align-items-center">
                <div className="col-12 d-flex align-items-center justify-content-center">
                     <h1 class="mb-4">OperforceAI</h1>
                </div>
                <div className="col-12  d-flex align-items-center justify-content-center">
                <input class="form-control"style={{ height: "60px",width:'400px',minWidth:'250px',margin:'10px' }} type="text"   placeholder="User Name" onChange={(e)=>setUserName(e.target.value)} required />
                </div>                    
                <div className="col-12  d-flex align-items-center justify-content-center">
                <input class="form-control"style={{ height: "60px",width:'400px',minWidth:'250px',margin:'10px' }} type="password" onChange={(e)=>SetPassword(e.target.value)}  placeholder="Password" required />
                </div>
                <div className="col-12  d-flex align-items-center justify-content-center">
                <input class="form-control"style={{ height: "60px",width:'400px',minWidth:'250px',margin:'10px' }} type="password"  onChange={(e)=>setRetypePassword(e.target.value)} placeholder="Retype Password" required />
                </div> 
                {
                    error &&  <div className="col-12  d-flex align-items-center justify-content-center">
                        <p style={{color:'red'}}>{errorMessage}</p>
                    </div>
                }                         
                <div className="col-12  d-flex align-items-center justify-content-center">
                  <button type="submit" class="login-button" onClick={handleSubmit}>Update</button>
                </div>                    
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Update_Password;
